<template>
  <main class="flex-shrink-0">
    <div class="container">
      <div class="base-info mt-3">
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">年　　龄</span>： 38岁
          </div>
          <div class="col">
            <span data-v-070132fe="">性　　别</span>： 男
          </div>

        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">籍　　贯</span>： 湖南浏阳
          </div>
          <div class="col">
            <span data-v-070132fe="">工作年限</span>： 15年
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">电　　话</span>： <a href="tel:+8613428974866">13428974866</a>
          </div>
          <div class="col">
            <span data-v-070132fe="">邮　　箱</span>：<a
              href="364756334@qq.com">369746540@qq.com</a>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">微　　信</span>： <span @click="copyString('.copy-btn-wx')" data-clipboard-text="roseking2014" class="copy-btn-wx">roseking2014&nbsp;(点击复制)</span>
          </div>
          <div class="col">
            <span data-v-070132fe="">QQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>：<span
              @click="copyString('.copy-btn-qq')" class="copy-btn-qq" data-clipboard-text="369746540" >369746540&nbsp;(点击复制)</span>
          </div>
        </div>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          技能特长
        </div>
        <ul class="mr-3">
          <li>
            十几年项目开发经验，多年项目管理经验，项目经验极其丰富，熟悉软件开发的各个阶段的工作。精通Java技术，熟练使用市面上各种主流开发框架和数据库技术。多年的一线工作养成了良好的工作习惯，具备独立快速解决问题的能力，做事认真负责，注重团队协作，良好的沟通表达能力，业务知识丰富，可针对客户的需求提出相应的解决方案，为客户带来不一样的项目体验！
          </li>
          <li>
            精通Java语言，熟练掌握Springmvc，Spring，Springboot，SpringCloud， MyBatis，MyBatis-plus，Struts2, Hibernate等后端开源框架；
          </li>
          <li>
            熟练使用JavaScript，Jquery，FreeMarker，Thymeleaf， Vuejs，Nodejs等前端技术；
          </li>
          <li>
            熟练使用Oracle、MySQL等关系型数据库，熟练使用MongoDb非关系型数据库，能对数据库进行读写分离，分库分表，分片等；
          </li>
          <li>
            熟练使用Redis，Memcache，Ehcache等缓存，熟练运用Redis特性到不同的业务系统；
          </li>
          <li>
            熟练使用Java中间件的集成和使用，消息队列（MQ），kafka等；
          </li>
          <li>
            熟悉全文搜索引擎Elasticsearch
          </li>
          <li>
            熟练使用Jenkins持续集成、Docker容器、Apollo配置中心等
          </li>
          <li>
            熟练操作Linux系统，项目发布、日常运维、问题排查。有高并发、高可用实际项目经验
          </li>
        </ul>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          工作经验
        </div>
        <dl class="row mr-3">
          <dd class="col">2019.04 <i class="ffyh">~</i> 2021.02</dd>
          <dd class="col-5"><b>湖南美食流智能科技有限公司</b></dd>
          <dd class="col">技术架构</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2017.02 <i class="ffyh">~</i> 2019.04</dd>
          <dd class="col-5"><b>深圳华海乐盈集团</b></dd>
          <dd class="col">技术架构</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2013.07 <i class="ffyh">~</i> 2017.01</dd>
          <dd class="col-5"><b>深圳一二三零八网络科技有限公司</b></dd>
          <dd class="col">技术架构</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2011.06 <i class="ffyh">~</i> 2013.06</dd>
          <dd class="col-5"><b>深圳腾邦集团</b></dd>
          <dd class="col">技术经理</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2009.05 <i class="ffyh">~</i> 2011.06</dd>
          <dd class="col-5"><b>深圳金蝶软件公司</b></dd>
          <dd class="col">高级Java开发工程师</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2007.06 <i class="ffyh">~</i> 2009.04</dd>
          <dd class="col-5"><b>深圳市思凯科技开发有限公司</b></dd>
          <dd class="col">Java开发工程师</dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          教育背景
        </div>
        <dl class="row mr-3">
          <dd class="col">2014.09 <i class="ffyh">~</i> 2016.12</dd>
          <dd class="col-5">东北大学</dd>
          <dd class="col">计算机科学与技术</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2004.09 <i class="ffyh">~</i> 2007.07</dd>
          <dd class="col-5">湖南科技职业技术学院</dd>
          <dd class="col">计算机科学与技术</dd>
        </dl>
      </div>
    </div>
  </main>
</template>

<script>
import Banner from '../../components/Home/Banner'
import Service from '../../components/Home/Service'
import Industry from '../../components/Home/Industry'
import Cooperate from '../../components/Home/Cooperate'
import Cases from '../../components/Home/Cases'
import Customer from '../../components/Home/Customer'
import News from '../../components/Home/News'
import FriendLink from '../../components/Home/FriendLink'
import Footer from '../../components/Footer'
import Clipboard from 'clipboard'

export default {
  name: "Resume",
  components: {Banner, Service, Industry, Cooperate, Cases, Customer, News, FriendLink, Footer},
  methods: {
    copyString(link) {
      console.log('复制内容', link)
      // this.link=link
      let clipboard = new Clipboard(link) // 这里括号里填写上面点击事件绑定的class名
      clipboard.on('success', (e) => {
        // 复制成功，提示根据自己项目实际使用的UI来写
        this.$toast.success('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        console.log('复制失败', e)
        // 不支持复制，提示根据自己项目实际使用的UI来写
        this.$toast.error('该浏览器不支持自动复制' + JSON.stringify(e.toString()))
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style scoped lang="less">
.base-info {
  font-size: 13px;
  color: #333;
  line-height: 2;
}

.title {
  font-size: 15px;
  color: #284967;
  background-color: rgba(40, 73, 103, 0.12);
  font-weight: 700;
}
</style>
