<template>
  <main class="flex-shrink-0">
    <div class="container">
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          夸夸课外培训教辅系统
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：培训管理</dd>
            <dd class="col-7">技术架构：Spring boot、Mybatis-plus、Redis、ECharts</dd>
          </dl>
          <li>
            一个应用于校外培训机构的小程序和管理平台，主要帮助学生提高学习兴趣，同时能为培训学校增加曝光度，拓展生源。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          湘辉灵活用工平台管理系统
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：用工平台</dd>
            <dd class="col-7">技术架构：Spring boot、Mybatis-plus、Redis、ECharts</dd>
          </dl>
          <li>
            湘辉灵活用工一体化服务平台，企业可以方便管理人员，为甲方寻找、筛选、匹配适合的自由职业者承接服务项目，向自由职业者宣贯服务内容、服务标准、注意事项等，并根据自由职业者服务成果与甲方结算服务费。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          仓库进销存系统
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：仓库进销存</dd>
            <dd class="col-7">技术架构：Spring boot、Mybatis-plus、Redis、ECharts</dd>
          </dl>
          <li>
            对企业生产经营中材料的入库、出库、调拨、付款等进行全程（从接获订单合同开始，进入物料采购、入库、领用到产品完工入库、交货、回收货款、支付原材料款等）跟踪、管理，有效辅助企业解决业务管理、分销管理、存货管理、营销计划的执行和监控、统计信息的收集等方面的业务问题
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          港天国际汽车城
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP、H5、管理平台</dd>
            <dd class="col-7">技术架构：Spring boot、Mybatis-plus、Redis、ECharts</dd>
          </dl>
          <li>
            华中地区规模最大的汽车交易综合体，各种价位、品牌型号的车型，都可以通过APP及后台数据查找到，车辆年检、出入场记录、及所有汽车相关数据，都可以在线查询。消费者购车再也不用东跑西走，上牌、过户，保险、按揭、车辆年检、违章处理等各项业务都可一站式办理。
            引进了查博士车辆检测接口，作为获得全国司法鉴定业务资格的检测机构，对车况进行检测，数据实时对接
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          Ekey Merchant
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：零售+餐饮</dd>
            <dd class="col-7">技术架构：Spring cloud、Mybatis-plus、Redis、RocketMq、Apollo</dd>
          </dl>
          <li>
            此App仅适用于零售、餐饮等商户，使商户能够创建他们唯一的营销平台
            在这个App中，商家可以创建凭证，包括姓名、号码、到期日期、费用等。
            商家可以在线购买代金券，定义一些促销，如抽奖，当客户中奖时每次抽奖，他们都会得到一张代金券。
            此外，该App提供了许多数据报告，以便商家做出更好的营销决策。
          </li>
          <dd class="col">下载地址：<a href="https://apps.apple.com/cn/app/id1612195181" target="_blank">AppStore</a> | <a
              href="https://play.google.com/store/apps/details?id=com.eKey.shop" target="_blank">Play商店</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          智慧食安公众平台/企业平台/监管平台
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：智慧食安</dd>
            <dd class="col-7">技术架构：Spring boot、Mybatis-plus、Redis、Mqtt、RocketMq</dd>
          </dl>
          <li>
            智慧食安系统分为三个平台，分别是监管端，机构端，公众端。监管端主要用于管部门远程实时对社会餐饮和校园餐饮工作的管理，机构端是提供给餐饮机构和学校后勤机构用于食品加工过程管理，公众端是面向社会公众和学生家长开放餐饮后厨食品加工过程的观看和监督。内含实时视频观看，餐饮评价，信息公示，检查评级记录，餐饮机构基础信息等内容
          </li>
        </dl>
        <dl class="row mr-3 ml-3">
          <dd class="col">监管端：<a href="https://apps.apple.com/cn/app/id1581625033" target="_blank">AppStore</a> | <a
              href="https://www.pgyer.com/59AH" target="_blank">蒲公英</a></dd>
        </dl>
        <dl class="row mr-3 ml-3">
          <dd class="col">企业端：<a href="https://apps.apple.com/cn/app/id1581706903" target="_blank">AppStore</a> | <a
              href="https://www.pgyer.com/aW4O" target="_blank">蒲公英</a></dd>
        </dl>
        <dl class="row mr-3 ml-3">
          <dd class="col">公众端：<a href="https://apps.apple.com/cn/app/id1581706933" target="_blank">AppStore</a> | <a
              href="https://www.pgyer.com/7uqh" target="_blank">蒲公英</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          橘子洲售票平台
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：互联网门票</dd>
            <dd class="col-7">技术架构：Spring cloud、Mybatis-plus、Redis、RocketMq</dd>
          </dl>
          <li>
            将景区门票放到线上进行销售，用户可以通过小程序、web、自主售卖机、票联网平台进行购买，线下闸机、手持POS等可对游客进行检票，支持个人票、亲子票、团体票，支持一票一检、一票多检。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          惠精选团购
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：社区团购</dd>
            <dd class="col-7">技术架构：SpringBoot、Mybatis、Mybatis-plus、Redis、Docker</dd>
          </dl>
          <li>
            惠精选是一款社区团购的电商app，支持多种类型的用户(会员，团长等)，为会员提供优质商品的选择服务，目前除了内地市场，还致力于新疆，内蒙市场的开拓。支持多种语言
          </li>
          <dd class="col">下载地址：<a href="http://www.lejingxuan.com/appdown.html" target="_blank">官网下载</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          餐饮交易平台
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：餐饮</dd>
            <dd class="col-7">技术架构：SpringBoot、Springcloud、Mybatis、Mybatis-plus、Mysql、Redis 、RocketMq、Mqtt</dd>
          </dl>
          <li>
            整个业务平台，是由商品服务、订单服务、支付服务、供应商服务、商家服务、小程序商城、代理服务、创业服务、定时任务、运营管理平台、商户管理平台、收银点单平台等多个系统构成。 项目采用当前比较流行的spring cloud 微服务系统架构，通过eureka来实现服务的注册与发现，统一网关过滤请求和验证，使用nginx实现负载均衡 ，使用分布式锁加数据库乐观锁保证数据的一致性，使用MQ来解耦和业务分离，分布式定时任务统计各类财务报表以及商户收益；单独的支付系统 ，给平台提交所有的资金交易接口；使用MQTT来实现硬件与系统之间的消息通信；使用Sentinel高可用流量管理框架来实时监控系统，实时统计和调 用链路的分析；JWT实现接口鉴权，使用GIT管理代码、Jenkins自动部署等
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          德恩教育
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：在线教育</dd>
            <dd class="col-7">技术架构：SpringBoot、Mybatis、Mybatis-plus、Redis</dd>
          </dl>
          <li>
            在线教育平台，平台视频支持直播和点播，学生可以在平台上发布个人遇到的问题，老师可以在线解答。老师可以在平台上发布上课时间，学生可预约老师课程。
          </li>
          <dd class="col">官网地址：<a href="https://www.deen12.com" target="_blank">官网地址</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          互联网彩票
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：互联网彩票</dd>
            <dd class="col-7">技术架构：Spring boot、Rabbitmq、Mybatis、Mybatis-plus、Redis</dd>
          </dl>
          <li>
            该项目是整个公司数字彩和竞技彩的交易系统，包括支付充值、用户钱包、退款、优惠券、抵扣卷、开奖、派奖、提款、交易流水等所有操作，平台对接几十家第三方支付公司，系统根据交易手续费、 维护时间、交易限额等智能分配支付渠道给用户支付。使用redis缓存及锁、mq的消息队列等主流技术，在世界杯期间用户量和交易量均达到峰值，日流水高达几千万，未产生资金异常
          </li>
          <dd class="col">下载地址：<a href="https://apps.apple.com/cn/app/id1308191405" target="_blank">AppStore</a> | <a
              href="https://www.wandoujia.com/apps/7679883" target="_blank">豌豆荚</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          网购GSS全球销售系统
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：B2B2C</dd>
            <dd class="col-7">技术架构：Spring mvc、ibatis、Webservice、memcached、Spring quartz</dd>
          </dl>
          <li>
            网购致力于打造中国实力最强的旅游分销平台，它以技术资源为优势，以机票、酒店业务为核心，以电子商务为发展方向，吸收了国际最先进的理念 ；采用中国航信IBE接口获取机票信息，调用艺龙接口获得全国各地酒店信息，保证了所有数据的精准、实时；网购拥有全国各地最具实力的政策供应 商，平台本身不销售机票，而是给一个机票销售平台，以绝对的价格优势吸引国内大批忠诚度极高的机票代理商在平台采购机票。 项目由机票系统，酒店系统，支付系统，采购商接口服务，供应商接口服务等构成，采购通接口提供机票相关业务给电商用户进行业务拓展，而供应 商接口则是获取供应商优势政策投放到平台，让平台采购商可以选取返点比较高的优势政策进行出票。 机票系统在struts1+jdbc的框架上进行开发和维护，运用oracle 11g数据库，应用服务器采用resin。 酒店项目采用Struts2，Spring，MyBatis搭建的全注解框架在weblogic应用服务器上进行开发，采用memcached集中式缓存系统对静态数据进行缓 存，使用oracle 11g数据库！项目用到的技术有memcached，Spring quartz，axis/xfire Webservice，jquery，ajax等
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          优购时尚商城
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：电商</dd>
            <dd class="col-7">技术架构：Spring Mvc、Mybatis、Webservice、rabbitMq、solr</dd>
          </dl>
          <li>
            优购网以经营优质、名品鞋类为主，以优质、时尚为网站定位，依托投资方百丽国际的供应链、资金以及品牌优势，并涉足深层次供应链管理，参与 货 品研发、设计、生产、零售等各个环节，以开放、合作、共赢的经营理念与众多品牌商建立紧密合作，争做中国领先的鞋品、服饰类优质时尚商品 网 络购物平台。 项目分B2C前台，商城后台，用户中心，联盟商管理，财务管理，采购管理，促销管理，支付模块，CMS，WMS管理系统，PDA仓库管理系统等;每个 模块在整个项目中都十分重要，缺一不可。项目中各模块并行开发，在预期时间内按时完成。项目用Spring Mvc框架实现，采用freemarker做为页面 展示层。持久层则用hibernate 和 jdbc结合。项目用到的技术包括JQuery，Ajax，Json，Jms，Webservice，birt，solr，rabbitMq，memcached，Spring quartz等
          </li>
          <dd class="col">网站地址：<a href="https://yougou.com/" target="_blank">点击查看</a>
          </dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          12308全国汽车票
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：互联网出行</dd>
            <dd class="col-7">技术架构：Spring Mvc、mybatis、mysql、mongodb、redis、rocketmq、elasticsearch、nginx、SSO等</dd>
          </dl>
          <li>
            提供全国汽车票、手机移动端、官方微信公众号、无线客户端、分销平台等平台。为旅客提供大巴包车、大巴旅游、汽车票网上购票、公路出行需求 的 在线咨询、汽车票、汽车时刻表查询、汽车站信息查询等综合性服务。减轻客运运营的压力，提高交通运载效力，安全出行、便捷出行，为旅客和 客 运企业提供一站式出行服务！ 网站分为PC主站，移动端（app，微信及wap），管理后台（内部管理平台，分销商管管理平台），商户管理平台，支付平台，车站代理，分销商接 口，火车票售票平台等用来支撑整个网站的运营。 项目前期采用多模块，后期用dubbo分布式服务框架进行开发，都是通过maven来管理。项目用memcached，redis做缓存，用MongoDB，mysq l 做数据存储层，用rocketMq做消息中间件。PC主站用到单点登录统一认证用户身份信息，管理后台采用shiro进行权限进行管理等，用spring boot微 框架快速开发一些小程序。
          </li>
        </dl>
      </div>

      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          金蝶企业应用协同系统
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：企业协同办公</dd>
            <dd class="col-7">技术架构：spring、mybatis、mysql、mongodb、linux、rabbitmq</dd>
          </dl>
          <li>
            提供给企业使用的一套互联网应用软件，包括项目管理、差旅管理、财务管理、企业微博等模块，让企业轻松管理出差人员的差旅费用，项目的进展情况及人员安排，财务费用的收入支出明细，以及企业内部员工之间的交流等
          </li>
        </dl>
      </div>
    </div>
  </main>
</template>

<script>
import Banner from '../../components/Home/Banner'
import Service from '../../components/Home/Service'
import Industry from '../../components/Home/Industry'
import Cooperate from '../../components/Home/Cooperate'
import Cases from '../../components/Home/Cases'
import Customer from '../../components/Home/Customer'
import News from '../../components/Home/News'
import FriendLink from '../../components/Home/FriendLink'
import Footer from '../../components/Footer'

export default {
  name: "Experience",
  components: {Banner, Service, Industry, Cooperate, Cases, Customer, News, FriendLink, Footer}
}
</script>

<style scoped lang="less">
.base-info {
  font-size: 13px;
  color: #333;
  line-height: 1.7;
}

.title {
  font-size: 15px;
  color: #284967;
  background-color: rgba(40, 73, 103, 0.12);
  font-weight: 700;
}
</style>
